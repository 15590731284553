<script setup lang="ts">
import { ref } from 'vue'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useLocalization } from '/~/composables/localization'
import { PayIdOrder } from '/~/composables/payment-methods'

withDefaults(
  defineProps<{
    payIdOrder: PayIdOrder
    loading: boolean
    isContinueHidden?: boolean
  }>(),
  {
    isContinueHidden: false,
  }
)

const emit = defineEmits<{
  (event: 'cancel' | 'continue'): void
}>()

const localization = useLocalization()

const isProcessing = ref(false)
const copiedToClipboard = ref('')

function onCancel() {
  modal.hide()
  emit('cancel')
}

function onContinue() {
  emit('continue')
}

async function writeClipboardText(text: string | number) {
  try {
    copiedToClipboard.value = text.toString()
    await navigator.clipboard.writeText(text.toString())
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="space-y-5">
      <div class="font-bold">
        Log in to your bank and use the PayID details below to make the payment.
      </div>
      <div>
        Please ensure all details are entered correctly at your bank. If the
        details are incorrect, the wrong account may be credited which may
        result in your funds not being recoverable. If the reference or amount
        is incorrect, your funds will be automatically returned.
      </div>
      <div>
        These details have also been sent to your email and will be valid until
        {{ payIdOrder.dateTime }}.
      </div>
    </div>
    <div class="mt-8 rounded border border-eonx-neutral-400 bg-white">
      <div class="space-y-3 p-5">
        <div class="justify-between xs:flex">
          <div>PayID (email)</div>
          <div class="flex space-x-3">
            <div
              v-if="loading"
              class="h-6 w-40 animate-pulse rounded bg-eonx-neutral-50"
            ></div>
            <span v-else class="max-w-64 truncate text-lg font-bold">
              {{ payIdOrder.id }}
            </span>
            <base-button
              v-tooltip="{
                content:
                  copiedToClipboard !== payIdOrder.id
                    ? 'Copy to clipboard'
                    : 'Copied!',
                placement: 'right',
                hideOnTargetClick: false,
                delay: { hide: 300 },
              }"
              icon="heroicons/outline/square-2-stack"
              alt="clipboard"
              :size="28"
              :padding="2"
              @click="writeClipboardText(payIdOrder.id)"
            />
          </div>
        </div>
        <div class="justify-between xs:flex">
          <div>PayID reference</div>
          <div class="flex space-x-3">
            <div
              v-if="loading"
              class="h-6 w-20 animate-pulse rounded bg-eonx-neutral-50"
            ></div>
            <span v-else class="text-lg font-bold">
              {{ payIdOrder.reference }}
            </span>
            <base-button
              v-tooltip="{
                content:
                  copiedToClipboard !== payIdOrder.reference
                    ? 'Copy to clipboard'
                    : 'Copied!',
                placement: 'right',
                hideOnTargetClick: false,
                delay: { hide: 300 },
              }"
              icon="heroicons/outline/square-2-stack"
              alt="clipboard"
              :size="28"
              :padding="2"
              @click="writeClipboardText(payIdOrder.reference)"
            />
          </div>
        </div>
        <div class="justify-between xs:flex">
          <div>Amount due</div>
          <div class="flex space-x-3">
            <div
              v-if="loading"
              class="h-6 w-20 animate-pulse rounded bg-eonx-neutral-50"
            ></div>
            <span v-else class="text-lg font-bold">
              {{ localization.formatMoney(payIdOrder.amount) }}
            </span>
            <base-button
              v-tooltip="{
                content:
                  copiedToClipboard !== Number(payIdOrder.amount).toString()
                    ? 'Copy to clipboard'
                    : 'Copied!',
                placement: 'right',
                hideOnTargetClick: false,
                delay: { hide: 300 },
              }"
              icon="heroicons/outline/square-2-stack"
              alt="clipboard"
              :size="28"
              :padding="2"
              @click="writeClipboardText(Number(payIdOrder.amount))"
            />
          </div>
        </div>
        <div class="text-sm">
          This PayID is registered to
          <span class="font-bold">EonX Services Pty Ltd</span>
        </div>
      </div>
    </div>
    <div
      class="pt-8"
      :class="{
        'grid  grid-cols-2 gap-3 pb-6': !isContinueHidden,
      }"
    >
      <base-button
        class="!h-12 w-full flex-auto"
        look="outlined-color"
        size="lge"
        @click="onCancel"
      >
        Cancel payment
      </base-button>
      <base-button
        v-if="!isContinueHidden"
        class="!h-12 flex-auto"
        size="lge"
        :disabled="isProcessing"
        @click="onContinue"
      >
        I understand
      </base-button>
    </div>
  </div>
</template>
